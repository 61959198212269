:root {
  --gutter: 2rem;
  --border-radius: 4px;

  // Max Content Widths
  --content-max-width-360: 328px;
  --content-max-width-640: 472px;
  --content-max-width-768: 704px;
  --content-max-width-1024: 704px;
  --content-max-width-1280: 880px;
  --content-max-width-1366: 966px;
  --content-max-width-1920: 1128px;

  // * Dynamic sizes
  --header-height: 56px;
  --max-container-width: 360px;
  --max-content-width: 328px;

  @media screen and (min-width: 640px) {
    --header-height: 56px;
    --max-container-width: 640px;
    --max-content-width: 472px;
  }

  @media screen and (min-width: 768px) {
    --header-height: 72px;
    --max-container-width: 768px;
    --max-content-width: 704px;
  }

  @media screen and (min-width: 1024px) {
    --header-height: 72px;
    --max-container-width: 1024px;
    --max-content-width: 704px;
  }

  @media screen and (min-width: 1280px) {
    --header-height: 56px;
    --max-container-width: 1280px;
    --max-content-width: 880px;
  }

  @media screen and (min-width: 1366px) {
    --header-height: 56px;
    --max-container-width: 1366px;
    --max-content-width: 966px;
  }

  @media screen and (min-width: 1920px) {
    --header-height: 56px;
    --max-container-width: 1920px;
    --max-content-width: 1128px;
  }
}
