.uv-action {
  position: relative;
  border-radius: 8px;
  text-transform: uppercase;
  width: fit-content;
  height: fit-content;
  padding: 18.5px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  font-family: Kanit, sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  z-index: 1;

  /* Background Noise */
  &::before {
    content: '';
    z-index: -2;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 7%;
    background-image: url('/assets/images/backgrounds/noise.png');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
  }

  /* Gradient Border */
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 1px;
    border-radius: 8px;
    background: linear-gradient(69.88deg, rgba(255, 255, 255, 0.3) 25.29%, rgba(255, 255, 255, 0.15) 93.26%);
    mask: linear-gradient(#000 0 0) exclude, linear-gradient(#000 0 0) content-box;
    -webkit-mask: linear-gradient(#000 0 0) exclude, linear-gradient(#000 0 0) content-box;
  }

  &--primary {
    &,
    &[type] {
      color: #ffffff;
      background: radial-gradient(50% 50% at 50% 50%, #7d89c4 0%, #4967fe 100%);
      text-shadow: 0px 0px 3px#FFFFFF40;
      box-shadow: 0px 8px 32px 0px#4462FF80;

      /* Gradient Border */
      &::after {
        background: linear-gradient(96.62deg, #cbd3fb 0%, #b9c0e7 100%);
      }

      &:hover,
      &:focus {
        box-shadow: 0px 8px 32px #4462ff80, 0px 4px 32px #ffffff47;

        /* Gradient Border */
        &::after {
          background: linear-gradient(96.62deg, #cbd3fb 0%, #b9c0e7 100%), linear-gradient(0deg, #ffffff, #ffffff);
        }
      }
    }
  }

  &--secondary {
    &,
    &[type] {
      color: #c9c9c9;
      background: radial-gradient(50% 50% at 50% 50%, rgba(90, 90, 94, 0.3) 0%, rgba(26, 26, 26, 0.3) 100%);
      backdrop-filter: blur(8px);

      /* Gradient Border */
      &::after {
        background: linear-gradient(93.91deg, rgba(250, 250, 250, 0.5) 0%, rgba(148, 148, 148, 0.5) 100%);
      }

      &:hover,
      &:focus {
        color: #fafafa;
        backdrop-filter: blur(8px);

        /* Gradient Border */
        &::after {
          background: linear-gradient(93.91deg, #fafafa 0%, #949494 100%);
        }
      }
    }
  }

  &--tertiary {
    &,
    &[type] {
      color: #c9c9c9;
      background: none;

      &::before,
      &::after {
        content: none;
      }

      &:hover,
      &:focus {
        color: #fafafa;
      }
    }
  }
}

// 480px+
@media only screen and (min-width: 480px) {
  // NOTE: no specific styles for this breakpoint
}

// 768px+
@media only screen and (min-width: 768px) {
  // NOTE: no specific styles for this breakpoint
}

// 1024px+
@media only screen and (min-width: 1024px) {
  // NOTE: no specific styles for this breakpoint
}

// 1480px+
@media only screen and (min-width: 1480px) {
  // NOTE: no specific styles for this breakpoint
}

// 1920px+
@media only screen and (min-width: 1920px) {
  // NOTE: no specific styles for this breakpoint
}
