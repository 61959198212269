/* Phonk-400 */
@font-face {
  font-family: Phonk;
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/phonk/400.woff2') format('woff2'), url('/assets/fonts/phonk/400.woff') format('woff');
}

/* Phonk-400-italic */
@font-face {
  font-family: Phonk;
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/phonk/400-italic.woff2') format('woff2'),
    url('/assets/fonts/phonk/400-italic.woff') format('woff');
}

/* Phonk-500 */
@font-face {
  font-family: Phonk;
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/phonk/500.woff2') format('woff2'), url('/assets/fonts/phonk/500.woff') format('woff');
}

/* Phonk-500-italic */
@font-face {
  font-family: Phonk;
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/phonk/500-italic.woff2') format('woff2'),
    url('/assets/fonts/phonk/500-italic.woff') format('woff');
}
