:root {
  /* Primary Colors Style */
  --primary-white-color: #ffffff;
  --secondary-white-color: #f5f5f5;
  --tertiary-white-color: #f0f0f0;
  --quaternary-white-color: #e1e1e1;
  --quinary-white-color: #c9c9c9;

  --primary-black-color: #100f11;
  --secondary-black-color: #1b1b1f;
  --tertiary-black-color: #28282a;
  --quaternary-black-color: #464649;
  --quinary-black-color: #808080;

  --primary-blue-color: #4462ff;
  --secondary-blue-color: #35f;
  --tertiary-blue-color: #5c77ff;
  --quaternary-blue-color: #8599ff;
  --quinary-blue-color: #adbbff;

  /* Secondary Colors Style */
  --primary-light-blue-color: #adddff;
  --secondary-light-blue-color: #99d5ff;
  --tertiary-light-blue-color: #c2e5ff;
  --quaternary-light-blue-color: #d6edff;
  --quinary-light-blue-color: #ebf6ff;

  --primary-purple-color: #c6adff;
  --secondary-purple-color: #b899ff;
  --tertiary-purple-color: #d4c2ff;
  --quaternary-purple-color: #e2d6ff;
  --quinary-purple-color: #f1ebff;

  /* Support Colors Style */
  --primary-orange-color: #ff9f47;
  --secondary-orange-color: #ff8b1f;
  --tertiary-orange-color: #ffab5c;
  --quaternary-orange-color: #ffc085;
  --quinary-orange-color: #ffd5ad;

  --primary-green-color: #70ffae;
  --secondary-green-color: #33ff8b;
  --tertiary-green-color: #85ffba;
  --quaternary-green-color: #adffd1;
  --quinary-green-color: #d6ffe8;

  --secondary-red-color: #f61313;
  --quaternary-red-color: #ff6969;

  /* Named Colors Style */
  --angular-red: #dd0031;
}
