/* Kanit-100 */
@font-face {
  font-family: Kanit;
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/kanit/100.woff2') format('woff2'), url('/assets/fonts/kanit/100.woff') format('woff');
}

/* Kanit-100-italic */
@font-face {
  font-family: Kanit;
  font-style: italic;
  font-weight: 100;
  src: url('/assets/fonts/kanit/100-italic.woff2') format('woff2'),
    url('/assets/fonts/kanit/100-italic.woff') format('woff');
}

/* Kanit-200 */
@font-face {
  font-family: Kanit;
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/kanit/200.woff2') format('woff2'), url('/assets/fonts/kanit/200.woff') format('woff');
}

/* Kanit-200-italic */
@font-face {
  font-family: Kanit;
  font-style: italic;
  font-weight: 200;
  src: url('/assets/fonts/kanit/200-italic.woff2') format('woff2'),
    url('/assets/fonts/kanit/200-italic.woff') format('woff');
}

/* Kanit-300 */
@font-face {
  font-family: Kanit;
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/kanit/300.woff2') format('woff2'), url('/assets/fonts/kanit/300.woff') format('woff');
}

/* Kanit-300-italic */
@font-face {
  font-family: Kanit;
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/kanit/300-italic.woff2') format('woff2'),
    url('/assets/fonts/kanit/300-italic.woff') format('woff');
}

/* Kanit-400 */
@font-face {
  font-family: Kanit;
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/kanit/400.woff2') format('woff2'), url('/assets/fonts/kanit/400.woff') format('woff');
}

/* Kanit-400-italic */
@font-face {
  font-family: Kanit;
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/kanit/400-italic.woff2') format('woff2'),
    url('/assets/fonts/kanit/400-italic.woff') format('woff');
}

/* Kanit-500 */
@font-face {
  font-family: Kanit;
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/kanit/500.woff2') format('woff2'), url('/assets/fonts/kanit/500.woff') format('woff');
}

/* Kanit-500-italic */
@font-face {
  font-family: Kanit;
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/kanit/500-italic.woff2') format('woff2'),
    url('/assets/fonts/kanit/500-italic.woff') format('woff');
}

/* Kanit-600 */
@font-face {
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/kanit/600.woff2') format('woff2'), url('/assets/fonts/kanit/600.woff') format('woff');
}

/* Kanit-600-italic */
@font-face {
  font-family: Kanit;
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/kanit/600-italic.woff2') format('woff2'),
    url('/assets/fonts/kanit/600-italic.woff') format('woff');
}

/* Kanit-700 */
@font-face {
  font-family: Kanit;
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/kanit/700.woff2') format('woff2'), url('/assets/fonts/kanit/700.woff') format('woff');
}

/* Kanit-700-italic */
@font-face {
  font-family: Kanit;
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/kanit/700-italic.woff2') format('woff2'),
    url('/assets/fonts/kanit/700-italic.woff') format('woff');
}

/* Kanit-800 */
@font-face {
  font-family: Kanit;
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/kanit/800.woff2') format('woff2'), url('/assets/fonts/kanit/800.woff') format('woff');
}

/* Kanit-800-italic */
@font-face {
  font-family: Kanit;
  font-style: italic;
  font-weight: 800;
  src: url('/assets/fonts/kanit/800-italic.woff2') format('woff2'),
    url('/assets/fonts/kanit/800-italic.woff') format('woff');
}

/* Kanit-900 */
@font-face {
  font-family: Kanit;
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/kanit/900.woff2') format('woff2'), url('/assets/fonts/kanit/900.woff') format('woff');
}

/* Kanit-900-italic */
@font-face {
  font-family: Kanit;
  font-style: italic;
  font-weight: 900;
  src: url('/assets/fonts/kanit/900-italic.woff2') format('woff2'),
    url('/assets/fonts/kanit/900-italic.woff') format('woff');
}
